.menu-rotate-open {
  animation: menu-open 250ms linear forwards;
}

.menu-rotate-close {
  animation: menu-close 250ms linear forwards;
}

@keyframes menu-open {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes menu-close {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.deposit-text-animation {
  animation: deposit-blink 1.5s ease 0s infinite normal none running;
}

@keyframes deposit-blink {
  0% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
  25% {
    -webkit-transform: scale(0.83);
    transform: scale(0.83);
  }
  50% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
  75% {
    -webkit-transform: scale(0.83);
    transform: scale(0.83);
  }
  100% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
}

.share-button {
  animation: share-rotate 6s linear infinite;
}

@keyframes share-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
  100% {
    -webkit-transform: rotate(2turn);
    transform: rotate(2turn);
  }
}

.container-no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.container-no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.alert-pulse {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.marquee {
  height: 38px;
  overflow: hidden;
  position: relative;
}

.marquee p {
  position: absolute;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 38px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 450px) {
  .header-logo {
    height: 7vw !important;
  }
}

.social-con > div {
  width: 218px;
}

.social-con button {
  margin: 0 !important;
  border-radius: 20px !important;
  padding: 9px 9px 9px 15px !important;
  height: auto !important;
  font-size: 16px !important;
  width: 218px !important;
}

.MuiDialogActions-root .MuiButton-root {
  color: #fff !important;
}

.telegram-btn {
  visibility: hidden;
  max-width: 0;
  margin-left: 0px !important;
}

.rainbow-box::before {
  margin: -5px;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 7px;
  background: linear-gradient(
    135deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.rhap_main-controls {
  flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 0 !important;
  flex: unset !important;
  justify-content: center !important;
}

.rhap_additional-controls {
  flex: unset !important;
}

.rhap_play-pause-button,
.rhap_skip-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rhap_main-controls-button {
  font-size: unset !important;
  flex: 1;
}

.rhap_container {
  background-color: green !important;
  border-radius: 10px !important;
  padding: 0px 15px !important;
}

.rhap_volume-controls {
  display: none !important;
}

.rhap_progress-section {
  display: none !important;
}

.page-maxwidth {
  max-width: 992px !important;
  margin-left: auto;
  margin-right: auto;
}

.mobile-nav-backdrop {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  background: rgb(22, 28, 36);
  background: -moz-linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
  background: -webkit-linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
  background: linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
  z-index: 1199;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.adjust-bottom-space {
  bottom: 8% !important;
}

.bg-gradient-gold {
  background: rgb(189, 144, 48);
  background: -moz-linear-gradient(
    90deg,
    rgba(189, 144, 48, 1) 0%,
    rgba(235, 218, 121, 1) 30%,
    rgba(235, 218, 121, 1) 43%,
    rgba(230, 188, 55, 1) 67%,
    rgba(230, 188, 55, 1) 72%,
    rgba(189, 144, 48, 1) 90%,
    rgba(189, 144, 48, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(189, 144, 48, 1) 0%,
    rgba(235, 218, 121, 1) 30%,
    rgba(235, 218, 121, 1) 43%,
    rgba(230, 188, 55, 1) 67%,
    rgba(230, 188, 55, 1) 72%,
    rgba(189, 144, 48, 1) 90%,
    rgba(189, 144, 48, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(189, 144, 48, 1) 0%,
    rgba(235, 218, 121, 1) 30%,
    rgba(235, 218, 121, 1) 43%,
    rgba(230, 188, 55, 1) 67%,
    rgba(230, 188, 55, 1) 72%,
    rgba(189, 144, 48, 1) 90%,
    rgba(189, 144, 48, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bd9030",endColorstr="#bd9030",GradientType=1);
}

.bg-gradient-gray {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(56, 56, 56, 1) 35%,
    rgba(56, 56, 56, 1) 65%,
    rgba(0, 0, 0, 1) 100%
  ) !important;
}

.gold-border-top-bot {
  position: relative;
}

.gold-border-top-bot::before,
.gold-border-top-bot::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(196, 150, 59, 1) 0%,
    rgba(221, 198, 116, 1) 25%,
    rgba(221, 198, 116, 1) 45%,
    rgba(191, 149, 59, 1) 100%
  );
}

.gold-border-top-bot::before {
  top: 0;
}

.gold-border-top-bot::after {
  bottom: 0;
}

.gold-border-wrapper {
  background-color: transparent;
  background-image: linear-gradient(180deg, #292929 0%, #020202 100%) !important;
  border-style: solid !important;
  border-color: #f2c556 !important;
  border-radius: 8px;
}

.gray-gradient-side {
  background: rgb(60, 60, 60);
  background-image: linear-gradient(
    90deg,
    rgba(60, 60, 60, 1) 0%,
    rgba(60, 60, 60, 1) 5%,
    rgba(0, 0, 0, 1) 15%,
    rgba(0, 0, 0, 1) 85%,
    rgba(60, 60, 60, 1) 95%,
    rgba(60, 60, 60, 1) 100%
  ) !important;
}

.gray-gradient-bot-top {
  background-image: linear-gradient(180deg, #292929 0%, #020202 100%) !important;
}

.gold-border {
  border: 2px solid #f2c556 !important;
}

/* Loading Spinner */
.lds-ellipsis {
  /* change color here */
  color: #fff;
}
.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  /* height: 80px; */
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* END Loading Spinner */

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';
  width: 48px;
  height: 48px;
  border: 2px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  animation: rotation 2s ease-in-out infinite;
}
.loader::after {
  border-color: #ff3d00;
  animation-delay: 1s;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-page:not(:empty) {
  color: #fff;
}
